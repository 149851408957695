<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">{{ $store.getters['languageMixin/getStringFromLanguage']('French Section') }}</h5>
      <div class="row">
        <div class="col-md-12 col-sm-12">

<!--          Category-->
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.category_id"
                rules="required"
                name="The Category"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="category_id"
                  size="large"
                  filterable
                  clearable
                  :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Category')"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="$store.getters['languageMixin/getStringFromLanguage']('Category')"
                  :list="builderData.categoryList"
                  :listItemLabel="'name'"
                  :listItemValue="'id'"
                  v-model="formData.category_id">
                </fg-select>
              </ValidationProvider>
            </div>
          </div>

<!--          Title & url-->
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.title"
                rules="required"
                name="The Title"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Title')"
                          name="title"
                          @keyup="generateSomeFields"
                          fou
                          v-model="formData.title">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.url"
                rules="required"
                name="The URL"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('URL')"
                          name="url"
                          fou
                          v-model="formData.url">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>


          <!-- Brief-->
          <div class="form-group">
            <label>{{ this.$store.getters['languageMixin/getStringFromLanguage']('Brief') }}</label>
            <editor
              v-model="formData.brief"
              :api-key="editorKey"
              :init='editorConfig'
            />
          </div>

<!-- Contributors-->
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.contributors"
                rules="required"
                name="The Contributors"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="contributors"
                  size="large"
                  filterable
                  clearable
                  multiple
                  :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Contributors')"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="$store.getters['languageMixin/getStringFromLanguage']('Contributors')"
                  :list="builderData.teamList"
                  :listItemLabel="'name'"
                  :listItemValue="'id'"
                  v-model="formData.contributors">
                </fg-select>
              </ValidationProvider>
            </div>
          </div>

<!--          Cost & Difficulty-->
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.cost"
                rules="required"
                name="The Cost"
                v-slot="{ passed, failed,errors }">
                <fg-input type="number"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Cost')"
                          name="cost"
                          fou
                          v-model="formData.cost">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.difficulty"
                rules=""
                name="The Difficulty"
                v-slot="{ passed, failed,errors }">
                <fg-input type="number"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Difficulty')"
                          name="difficulty"
                          fou
                          v-model="formData.difficulty">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>


<!--          Content-->
          <div class="form-group">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Content') }}</label>
            <editor
              v-model="formData.content"
              :api-key="editorKey"
              :init='editorConfig'
            />
          </div>

<!--          Image-->
          <div class="form-group">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Image')}}</label>
            <el-tooltip placement="right" v-if="false">
              <div slot="content"></div>
              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
            </el-tooltip>
            <prime-uploader
              :preview-width="'200px'"
              :preview-height="'200px'"
              :elementNum="1"
              :maxFileSize="2000000"
              :isEditMode="editMode"
              v-model="formData.image"
            >
            </prime-uploader>
          </div>


<!--          Prerequisites-->
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.prerequisites"
                rules=""
                name="The Prerequisites"
                v-slot="{ passed, failed,errors }">
                <fg-text type="text"
                         :error="failed ? errors[0]: null"
                         :label="$store.getters['languageMixin/getStringFromLanguage']('Prerequisites')"
                         name="prerequisites"
                         fou
                         v-model="formData.prerequisites">
                </fg-text>
              </ValidationProvider>
            </div>
          </div>
          <!-- Extra fields -->

          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.audience_target"
                rules=""
                name="The Audience"
                v-slot="{ passed, failed,errors }">
                <fg-text type="text"
                         :error="failed ? errors[0]: null"
                         :label="$store.getters['languageMixin/getStringFromLanguage']('Audience')"
                         name="audience_target"
                         fou
                         v-model="formData.audience_target">
                </fg-text>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider
                vid="fr.places"
                rules=""
                name="The Places"
                v-slot="{ passed, failed,errors }">
                <fg-input type="number"
                         :error="failed ? errors[0]: null"
                         :label="$store.getters['languageMixin/getStringFromLanguage']('Places')"
                         name="places"
                         fou
                         v-model="formData.places">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-group">
            <label>{{ this.$store.getters['languageMixin/getStringFromLanguage']('Notes') }}</label>
            <editor
              v-model="formData.notes"
              :api-key="editorKey"
              :init='editorConfig'
            />
          </div>



          <!-- end extra fields -->

<!--          Lists-->
          <div class="row">

            <!--           Teaching Methods list items-->
            <div class="col-md-6">
              <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Teaching Methods List') }}</label>
              <div class="col-12">
                <template class="row" v-for="(listItem,listItemIndex) in formData.teaching_methods">
                  <div class="row team_list_items">
                    <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Item') }} # {{ listItemIndex + 1 }}</label>
                    <!--                list item title-->
                    <div class="col-md-12">
                      <ValidationProvider
                        vid="fr.teaching_methodstitle"
                        rules=""
                        name="The Title"
                        v-slot="{ passed, failed,errors }">
                        <fg-input type="text"
                                  :key="'title_'+listItemIndex"
                                  :error="failed ? errors[0]: null"
                                  :label="$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)"
                                  v-model="formData.teaching_methods[listItemIndex].title">
                        </fg-input>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-sm-1 align-self-center mt-3">
                    <l-button
                      @click="removeItem(formData.teaching_methods, listItemIndex)"
                      type="danger" size="sm">
                      <i class="fa fa-trash"></i>
                    </l-button>
                  </div>
                </template>
                <l-button
                  class="mt-3"
                  type="success"
                  @click="addNewItem(formData.teaching_methods, {title: ''})"
                  size="sm">
                  <i class="fa fa-plus"></i>
                </l-button>
              </div>
            </div>

            <!--            Volunteering list items-->
            <div class="col-md-6">
              <label>{{ $store.getters['languageMixin/getStringFromLanguage']('After The Training List') }}</label>
              <div class="col-12">
                <template class="row" v-for="(listItem,listItemIndex) in formData.after_training">
                  <div class="row team_list_items">
                    <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Item') }} # {{ listItemIndex + 1 }}</label>
                    <!--                list item title-->
                    <div class="col-md-12">
                      <ValidationProvider
                        vid="fr.after_trainingtitle"
                        rules=""
                        name="The Title"
                        v-slot="{ passed, failed,errors }">
                        <fg-input type="text"
                                  :key="'title_'+listItemIndex"
                                  :error="failed ? errors[0]: null"
                                  :label="$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)"
                                  v-model="formData.after_training[listItemIndex].title">
                        </fg-input>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-sm-1 align-self-center mt-3">
                    <l-button
                      @click="removeItem(formData.after_training, listItemIndex)"
                      type="danger" size="sm">
                      <i class="fa fa-trash"></i>
                    </l-button>
                  </div>
                </template>
                <l-button
                  class="mt-3"
                  type="success"
                  @click="addNewItem(formData.after_training, {title: ''})"
                  size="sm">
                  <i class="fa fa-plus"></i>
                </l-button>
              </div>
            </div>
          </div>

          <div class="row">
            <!--        Goals list items-->
            <div class="col-md-6">
              <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Goals List') }}</label>
              <div class="col-12">
                <template class="row" v-for="(listItem,listItemIndex) in formData.goals">
                  <div class="row team_list_items">
                    <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Item') }} # {{ listItemIndex + 1 }}</label>
                    <!--                list item title-->
                    <div class="col-md-12">
                      <ValidationProvider
                        vid="fr.goalstitle"
                        rules=""
                        name="The Title"
                        v-slot="{ passed, failed,errors }">
                        <fg-input type="text"
                                  :key="'title_'+listItemIndex"
                                  :error="failed ? errors[0]: null"
                                  :label="$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)"
                                  v-model="formData.goals[listItemIndex].title">
                        </fg-input>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-sm-1 align-self-center mt-3">
                    <l-button
                      @click="removeItem(formData.goals, listItemIndex)"
                      type="danger" size="sm">
                      <i class="fa fa-trash"></i>
                    </l-button>
                  </div>
                </template>
                <l-button
                  class="mt-3"
                  type="success"
                  @click="addNewItem(formData.goals, { title: ''})"
                  size="sm">
                  <i class="fa fa-plus"></i>
                </l-button>
              </div>
            </div>
            <!--        Scientific Programmes list items-->
            <div class="col-md-6">
              <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Scientific Programmes') }}</label>
              <div class="col-12">
                <template class="row" v-for="(listItem,listItemIndex) in formData.scientific_programmes">
                  <div class="row team_list_items">
                    <label>{{$store.getters['languageMixin/getStringFromLanguage']('Item') }} # {{ listItemIndex + 1 }}</label>
                    <!--                list item title-->
                    <div class="col-md-12">
                      <ValidationProvider
                        vid="fr.scientific_programmestitle"
                        rules=""
                        name="The Title"
                        v-slot="{ passed, failed,errors }">
                        <fg-input type="text"
                                  :key="'title_'+listItemIndex"
                                  :error="failed ? errors[0]: null"
                                  :label="$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)"
                                  v-model="formData.scientific_programmes[listItemIndex].title">
                        </fg-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-12">
                      <ValidationProvider
                        vid="fr.scientific_programmes_subtitle"
                        rules=""
                        name="The Subtitle"
                        v-slot="{ passed, failed,errors }">
                        <fg-input type="text"
                                  :key="'subtitle_'+listItemIndex"
                                  :error="failed ? errors[0]: null"
                                  :label="$store.getters['languageMixin/getStringFromLanguage']('Subtitle')+' '+(listItemIndex+1)"
                                  v-model="formData.scientific_programmes[listItemIndex].subtitle">
                        </fg-input>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-sm-1 align-self-center mt-3">
                    <l-button
                      @click="removeItem(formData.scientific_programmes, listItemIndex)"
                      type="danger" size="sm">
                      <i class="fa fa-trash"></i>
                    </l-button>
                  </div>
                </template>
                <l-button
                  class="mt-3"
                  type="success"
                  @click="addNewItem(formData.scientific_programmes, { title: '', subtitle: ''})"
                  size="sm">
                  <i class="fa fa-plus"></i>
                </l-button>
              </div>
            </div>
          </div>

          <!--          gallery-->
          <div class="form-group">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Gallery') }}</label>
            <el-tooltip placement="right" v-if="false">
              <div slot="content"></div>
              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
            </el-tooltip>
            <prime-uploader
              :preview-width="'200px'"
              :preview-height="'200px'"
              :elementNum="999"
              v-model="formData.gallery"
            >
            </prime-uploader>
          </div>


          <!--          Second gallery-->
          <div class="form-group">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Second Gallery') }}</label>
            <el-tooltip placement="right" v-if="false">
              <div slot="content"></div>
              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
            </el-tooltip>
            <prime-uploader
              :preview-width="'200px'"
              :preview-height="'200px'"
              :elementNum="999"
              v-model="formData.gallery2"
            >
            </prime-uploader>
          </div>

          </div>







      </div>

      <div class="form-group pt-4">
        <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Is Active') }}</label>&nbsp;
        <l-switch v-model="formData.is_active">
          <i class="fa fa-check" slot="on"></i>
          <i class="fa fa-times" slot="off"></i>
        </l-switch>
      </div>
      <div class="form-group pt-4">
        <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Is Featured') }}</label>&nbsp;
        <l-switch v-model="formData.is_featured">
          <i class="fa fa-check" slot="on"></i>
          <i class="fa fa-times" slot="off"></i>
        </l-switch>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import {Option, Select, Tooltip} from "element-ui";
  import FgSelect from "@/components/Inputs/formGroupSelect";
  import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
  import Editor from '@tinymce/tinymce-vue'

  extend("required", required);
  extend("email", email);

  export default {
    components: {
      FormGroupTextArea,
      [Option.name]: Option,
      [Select.name]: Select,
      [Tooltip.name]: Tooltip,
      FgSelect,
      LSwitch,
      PrimeUploader,
      ImageUploader,
      PrimeVideoUploader,
      'editor': Editor
    },
    props: {
      lang: {
        type: String,
        default: 'fr'
      },
      editMode: {
        type: Boolean,
        default: false
      },
      builderData:{},
      formData:{
        type: Object,
        default() {
          return {
            category_id: "",
            title: "",
            url: "",
            brief:"",
            image: "",
            content:"",
            difficulty: 0,
            cost: 0,
            prerequisites: '',
            teaching_methods: [],
            after_training: [],
            goals: [],
            scientific_programmes: [],
            contributors: [],
            gallery: [],
            gallery2: [],
            audience_target : '',
            notes : '',
            places: 0,
            show_on_navbar: false,
            is_active: true,
            is_featured: false,
          }
        }
      },
    },
    data() {
      return {
        editorConfig: this.getEditorConfig(),
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      };
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          if (res){
            this.$emit('submit-step', this.formData, this.lang)
          }else{
            this.$emit('on-validated', res)
          }
          return res
        });
      },
      generateSomeFields() {
        if (!this.editMode){
          let newUrl = '';
          if (this.formData.title) {
            newUrl = this.formData.title.replace(/\s+/g, "-").toLowerCase();
            newUrl = this.removeAccents(newUrl);
            newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
            newUrl = newUrl.replace(/([-]+)/g, "-");
          }

          this.formData.url = newUrl;
        }
      },
      addNewItem(listItems, initObject) {
        listItems.push(initObject);
      },
      removeItem(listItems, index) {
        listItems.splice(index, 1);
      },
    }
  }
</script>
<style>
</style>
